<template>
	<div class="login_wrapper" id="mydiv">
		<div class="login" id="Vcode">
			<el-form :model="loginUserInfo" v-if="!isTapLogin">
				<el-form-item><h2 class="title">后台管理系统</h2></el-form-item>
				<el-form-item><el-input v-model="loginUserInfo.username" placeholder="账号"></el-input></el-form-item>
				<el-form-item><el-input type="password" v-model="loginUserInfo.password" placeholder="密码"></el-input></el-form-item>
				<el-form-item>
					<el-button type="primary" @click="login">登陆</el-button>
					<span v-show="this.errorInfo.isShowError" class="error">{{ this.errorInfo.text }}</span>
				</el-form-item>
			</el-form>
			<!-- <slide-verify  :l="42" :r="10" :w="460" :h="256" slider-text="向右滑动" @success="onSuccess" @fail="onFail"></slide-verify> -->
			<Vcode v-else :show="isTapLogin" :canvasWidth="460" :canvasHeight="200" @success="onSuccess"/></Vcode> <!-- :-->
		</div>
		<p class="copy-right">备案号</p>
	</div>
</template>

<script>
import { CanvasParticle } from '../plugins/particle.js';
// import Vcode from "vue-puzzle-vcode";
export default {
	name: 'login',
	data() {
		return {
			// imgs:[],//滑块自定义图片数组
			isTapLogin: false,
			loginUserInfo: {
				username: 'admin',
				password: ''
			},
			errorInfo: {
				text: '登陆失败,请重试',
				isShowError: false //显示错误提示
			}
		};
	},
	// components:{Vcode},
	mounted() {
		console.log("aaaaaa");
		CanvasParticle(config);
		const config = {
			vx: 4, //小球x轴速度,正为右，负为左
			vy: 4, //小球y轴速度
			height: 2, //小球高宽，其实为正方形，所以不宜太大
			width: 2,
			count: 300, //点个数
			color: '255, 255, 255', //点颜色
			stroke: '255,255,255', //线条颜色
			dist: 6000, //点吸附距离
			e_dist: 20000, //鼠标吸附加速距离
			max_conn: 10 //点到点最大连接数
		};
		document.onkeydown = event => {
			var e = event || window.event || arguments.callee.caller.arguments[0];
			if (e && e.keyCode == 13 && this.$route.path == '/login') {
				// enter 键
				this.login();
			}
		};
	},
	methods: {
		async onSuccess() {
			this.isTapLogin = false;
			console.log(JSON.stringify(this.loginUserInfo))
			const res = await this.$axios('post', '/sys/yunke/mgr/adminlogin', this.loginUserInfo);
			this.$message.success('登录成功!');
			const {token,userInfo} = res
			this.$store.commit('user/user_setUserData', userInfo);
			this.$store.commit('user/user_tokenType', token.tokenName);
			this.$store.commit('user/user_setToken', token.tokenValue);
			this.$store.commit('user/user_setLogin', true);
			setTimeout(() => {
				this.$router.push('/');
			}, 800);
		},
		// onFail() {
		// 	this.$message.success('验证失败!');
		// },
		// onRefresh() {
		// 	this.msg = '';
		// },
		async login() {
			this.isTapLogin = true;
			// 模拟登陆
			// if(this.loginUserInfo.password == "123456"){
			// 	this.$message.success('登录成功!')
			// 	this.$store.commit("user_setUserData",this.loginUserInfo)
			// 	this.$store.commit("user_setToken","sssss")
			// 	this.$store.commit("user_setLogin",true)
			// 	setTimeout(()=>{
			// 		this.$router.push('/');
			// 	},800)
			// }
			// else{
			// 	this.errorInfo.isShowError = true;
			// 	this.errorInfo.text = '用户名或密码错误！';
			// }
		}
	}
};
</script>

<style lang="scss">
$input_width: 300px;
// body{
// 	background-color: $login_bgcolor;
// }
.login_wrapper {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	.login {
		width: 460px;
		height: 296px;
		margin-top: -150px;
		border: 1px solid #eaeaea;
		box-shadow: 0 0 25px #cac6c6;
		@include flex-style(row, nowrap, flex-start, center); 
		.title {
			text-align: center;
			color: #505458;
		}
		.el-form-item__content {
			width: $input_width;
		}
		.el-button {
			width: $input_width;
		}
		.el-form {
			margin: 30px 80px auto 80px;
			.error {
				display: block;
				text-align: center;
				color: red;
			}
		}
	}
}
.copy-right{
	position: fixed;
	bottom: 4px;
	left: 0;
	right: 0;
	text-align: center;
	font-size: 12px;
	color: #999;
}

// .slide-verify-slider {
// 	margin-top: -4px !important;
// 	border: 0 !important; 
// }
.vue-puzzle-vcode{
	width: 460px !important;
	height: 296px !important;
	position: initial;
	.vue-auth-box_{
		position: initial;
		width:100%;
		height:100%;
		box-sizing: border-box;
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
	}
}
</style>
